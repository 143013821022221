import React from 'react';
import { css } from '@emotion/react';
import { useStaticQuery, graphql } from "gatsby"
import Link from '../components/link';
import BackgroundImage from "gatsby-background-image-es5"
import { wrapper, ArrowLink, TitleHeader } from '../components/defaults/styles/elements';
import { color } from '../components/defaults/styles/theme';
import mq from '../components/defaults/styles/mediaquerys';
import moment from "moment"
import Img from "gatsby-image"
import More from "../components/defaults/assets/svg/more_horizontal.svg"
import Trending from "../components/defaults/assets/svg/trending-up.svg"
import { ArrowRight } from '../components/defaults/assets/svg/pagination';


const UdTitle = ({title, theme}) => {
  return (
    <div>
      <h3 css={css`
        color: ${theme ? theme : color.main_dark};
        text-decoration: none;
        max-width: 90%;
        margin-bottom: .25em;
        line-height: 1.3em;
        font-size: 1.2em;
      `}>{title}</h3>
    </div>
  )
}

const UdDate = ({date, theme}) => {
  const dateString = moment(date).format("Do of MMMM yyyy");

  return(
    <div>
      <p css={css`
        font-size: .75em;
        color: ${theme ? theme : color.main_dark};
        font-weight: 500;
        letter-spacing: .035em;
        margin-bottom: 1.5em;
      `}>
        {dateString}
      </p>
    </div>
  )
}

const UdAuthor = ({author, className, theme}) => {

  const author_company = author.partner_company && author.partner_company.length > 0 ? author.partner_company[0].data.name : author.startup_company && author.startup_company.length > 0 ? author.startup_company[0].data.startup : author.custom_company ? author.custom_company : ''
  return (
    <div className={className}>
      <p css={css`
        font-size: .75em;
        color: ${theme ? theme : color.main_dark};
        font-weight: 500;
        letter-spacing: .035em; 
        margin: 0;
      `}>
        by {author.name}, {author_company}
      </p>
    </div>
  )
}

let UdMainElement = css`
  flex: 0 0 100%;
  text-decoration: none;
  margin-bottom: 4em;
  ${mq[1]} {
    min-height: 17em;
    flex: 0 0 calc((100% - 2em)/2);
    margin-bottom: 3
  }
  ${mq[2]} {
    flex: 0 0 calc((100% - 6em)/3);
    margin-bottom: 4em;
    min-height: 20em;
  }
`

let UdPlaceholderElement = css`
  margin-bottom: 0;
  min-height: unset;
  ${mq[2]} {
    min-height: unset;
    margin-bottom: 0;
  }
`

const BgImageCard = ({data}) => {
  return (
    <div css={css`
      display: flex;
      overflow: hidden;
      border-radius: 5px;
      height: 100%;
    `}>
      <BackgroundImage
        fluid={data.update_image.localFiles[0].childImageSharp.fluid}
        css={css`
          width: 100%;
          height: 100%;
          display: flex;
          &::before,
          &::after {
            filter: blur(3px);
          }
        `}
      >
        <div css={css`
          background: linear-gradient(
            to bottom,
            hsla(0, 0%, 0%, 0) 0%,
            hsla(0, 0%, 0%, 0.044) 9.2%,
            hsla(0, 0%, 0%, 0.089) 17.2%,
            hsla(0, 0%, 0%, 0.135) 24.3%,
            hsla(0, 0%, 0%, 0.182) 30.6%,
            hsla(0, 0%, 0%, 0.228) 36.4%,
            hsla(0, 0%, 0%, 0.275) 41.7%,
            hsla(0, 0%, 0%, 0.322) 46.7%,
            hsla(0, 0%, 0%, 0.368) 51.8%,
            hsla(0, 0%, 0%, 0.413) 56.9%,
            hsla(0, 0%, 0%, 0.458) 62.3%,
            hsla(0, 0%, 0%, 0.502) 68.2%,
            hsla(0, 0%, 0%, 0.544) 74.7%,
            hsla(0, 0%, 0%, 0.584) 82.1%,
            hsla(0, 0%, 0%, 0.623) 90.5%,
            hsla(0, 0%, 0%, 0.66) 100%
          );
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          padding: 3em 1em 1em 1em;
        `}>
          <UdTitle title={data.update_title} theme="white" />
          <UdDate date={data.update_date} theme="white" />
          <UdAuthor author={data.update_author[0].data} theme="white" />
        </div>

      </BackgroundImage>

    </div>
  )  
}

const ImageCard = ({data}) => {
  return (
    <div css={css`
      height: 100%;
      display: flex;
      flex-direction: column;
    `}>
      <div>
        <Img 
          fluid={data.update_image.localFiles[0].childImageSharp.fluid}
          css={css`
            height: 11em;
            border-radius: 5px;
          `}
        />
      </div>

      <div css={css`
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 1.5em 1em 1em 1em;
      `}>
        <UdTitle title={data.update_title} />
        <UdDate date={data.update_date} />
        <UdAuthor author={data.update_author[0].data} />
      </div>
    </div>
  )  
}

const PlainCard = ({data}) => {

  
  return (
    <div>
      <UdTitle title={data.update_title} />
      <UdDate date={data.update_date} theme="#bfbfbfbf" />
      <div dangerouslySetInnerHTML={{__html: data.update_content.childMarkdownRemark.excerpt}}
        css={css`
          color: ${color.main_dark};
          font-size: .9em;
          p {
            margin-bottom: .5em;
          }
          p:last-of-type {
            margin-bottom: 0em;
          }
        `}
      />
      
      {data.update_content.childMarkdownRemark.html.length > data.update_content.childMarkdownRemark.excerpt.length ? (
        <p css={css`
          font-size: .75em;
          opacity: .7;
          color: ${color.main_dark};
          letter-spacing: .025em;
          margin-top: .5em;
          display: flex;
          align-items: center;
          svg {
            height: 1em;
            width: auto;
            vertical-align: baseline;
            margin-left: .5em;
          }
        `}>Read more <ArrowRight /></p>
      ) : ''}

      {data.update_image && data.update_image.localFiles.length > 0 ? (
        <div css={css`
          display: flex;
          margin-top: 1em;
          >*:not(:last-child) {
            margin-right: .35em;
          }
        `}>
          {data.update_image.localFiles.map((image, index) => (
            index <= 2 ? (
              <div key={index} css={[css`
                width: 4em;
                height: 4em;
                overflow: hidden;
                border-radius: 5px;
              `]}>
                <Img fluid={image.childImageSharp.fluid} css={css`
                  height: 100%;
                  width: 100%;
                `}
                />
              </div>
            ) : ""
          ))}
          {data.update_image.localFiles.length > 3 ? (
            <div css={css`
              background: ${color.accent_light};
              width: 4em;
              height: 4em;
              overflow: hidden;
              border-radius: 5px;
              display: flex;
              align-items: center;
              justify-content: center;
              svg {
                stroke: white;
                fill:white;
                margin-left: -1px;
              }
            `}>
              <More />
            </div>
          ) : ""}
        </div>
      ) : ""}
      <UdAuthor author={data.update_author[0].data} css={css`
        margin-top: 1.5em;
      `} />
    </div>
  )  
}

const UpdateInner = () => {
  let postIndex = 0;
  const updatePostIndex = () => {
    postIndex++
  }
  const data = useStaticQuery(graphql`
    {
      allAirtable(filter: {table: {eq: "updates"}}, sort: {fields: data___update_date, order: DESC}) {
        edges {
          node {
            recordId
            data {
              publish
              update_author {
                data {
                  name
                  custom_company
                  startup_company {
                    data {
                      startup
                    }
                  }
                  partner_company {
                    data {
                      name
                    }
                  }
                }
              }
              update_content {
                childMarkdownRemark {
                  html
                  excerpt(format: HTML, truncate: true, pruneLength: 140)
                }
              }
              update_date
              update_style
              update_title
              update_attachement {
                localFiles {
                  publicURL
                }
              }
              update_image {
                localFiles {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <div css={css`
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    `}>
      {
        data.allAirtable.edges.map((update, index) => (
          update.node.data.publish && postIndex < 3 ? (
            <Link to={`/updates#${update.node.recordId}`} key={index} css={UdMainElement}>
            {
              update.node.data.update_style === "image" && update.node.data.update_image && update.node.data.update_image.localFiles.length > 0 ? (
                <ImageCard data={update.node.data} />
              ) : update.node.data.update_style === "bgimage" && update.node.data.update_image && update.node.data.update_image.localFiles.length > 0 ? (
                <BgImageCard data={update.node.data} />
              ) : (
                <PlainCard data={update.node.data} />
              )
            }

            { updatePostIndex() }
          </Link>
          ) :''
        ))
      }
      <div css={[UdMainElement, UdPlaceholderElement]} />
      <div css={[UdMainElement, UdPlaceholderElement]} />
    </div>
  )
}

const Updates = () => {
  return (
    <section css={css`
      background: white;
      padding: 4em 0;
    `}>
      <TitleHeader title="The EXPO 9 Updates" icon={<Trending />} subtitle="<p>Do not miss out any Expo 9 updates or website changelogs by monitoring this section of the website carefully. We keep you posted.</p>" css={css`
        padding-top: 0;
        padding-bottom: 1em;
      `} />
      <div css={wrapper}>
        <UpdateInner />
        <ArrowLink text="Check out more updates" url="/updates" css={css`
          color: ${color.main_dark};
          margin-top: 0;
          ${mq[2]} {
            margin-top: 0em;
          }
          svg {
            stroke: ${color.main_dark};
          }
        `}/>
      </div>
    </section>
  )
}

export default Updates;